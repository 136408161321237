import { Navigation } from "./navigation/navigation";
import type { HeaderType } from "../../types/strapi-types";
import ReactMarkdown from "react-markdown";
import { Logo } from "./logo";
import { NavLink } from "react-router-dom";
import { HeaderBg } from "./header-bg";
import type { StrapiReturn } from "../../types/strapi-return";

export function Header({
	minimize,
	data,
}: {
	minimize?: boolean;
	data?: StrapiReturn<HeaderType>;
}) {
	if (!data) {
		return null;
	}

	const { bg, tel, title, logo } = data.attributes;
	const bgData = bg?.data?.attributes;
	const logoData = logo.data.attributes;

	return (
		<div
			className={`${
				!minimize ? "min-h-[70vh]" : ""
			} h-full relative flex flex-col`}
		>
			{!!bgData && <HeaderBg {...bgData} />}

			<div className="w-full max-w-[1200px] mx-auto px-4 py-2 z-50 relative">
				<header className="flex gap-10 justify-between items-center h-24">
					<Logo {...logoData} />

					<Navigation tel={tel} />
				</header>
			</div>

			{!minimize && (
				<>
					<div className="flex-1" />

					<div className="w-full max-w-[1200px] mx-auto px-4 py-10 md:py-14 z-10 relative">
						<h2 className="text-center font-light [&_strong]:font-bold text-2xl sm:text-3xl md:text-5xl text-white">
							<ReactMarkdown className="drop-shadow">{title}</ReactMarkdown>
						</h2>

						<div className="flex justify-center mt-14">
							<NavLink
								to="/oferta"
								className="text-white bg-[var(--main-color)] hover:bg-white hover:text-[var(--main-color)] transition-colors duration-300 border-2 border-[var(--main-color)] font-bold px-8 py-3"
							>
								ZOBACZ OFERTĘ
							</NavLink>
						</div>
					</div>

					<div className="flex-1" />
				</>
			)}
		</div>
	);
}
