import { useEffect, useState } from "react";
import { getStrapiImageUrl } from "../../lib/get-strapi-image-url";

export function HeaderBg({ url }: { url: string }) {
	const [top, setTop] = useState(0);

	useEffect(() => {
		function handleScroll() {
			if (window.scrollY < 0) {
				requestAnimationFrame(() => setTop(0));
				return;
			}
			requestAnimationFrame(() => setTop(window.scrollY * 0.3));
		}

		window.addEventListener("scroll", handleScroll);

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	return (
		<div className="absolute top-0 left-0 w-full h-full z-0 overflow-hidden">
			<div className="absolute top-0 left-0 w-full h-full bg-black/30 z-10"/>
			<div
				className="absolute top-0 left-0 w-full h-full bg-cover"
				style={{
					backgroundImage: `url('${getStrapiImageUrl(url)}')`,
					top: `${top}px`,
				}}
			/>
		</div>
	);
}
